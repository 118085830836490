import React from 'react';
import { Administer } from 'cccisd-laravel-assessment';
import progressQuery from './progress.graphql';
import ShareLogo from '../../../images/SHARE-Logo.svg';

const AdministerPage = props => {
    return (
        <div>
            <Administer
                progressQuery={progressQuery}
                collectionBuckets={['Student-led', 'Provider-led']}
                contactTypes={['Student-led', 'Provider-led']}
                logo={ShareLogo}
                addToCollectionButtonLabel="Add to Plan"
            />
        </div>
    );
};

export default AdministerPage;
