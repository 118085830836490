import React from 'react';
import StudentTreatmentDashboard from '../../components/StudentTreatmentDashboard';
import progressQuery from './progress.graphql';
import ShareLogo from '../../../images/SHARE-Logo.svg';
import style from './style.css';

const DeploymentCenterPage = props => {
    const header = (
        <div>
            <div className={style.panel}>
                <div className={style.panelLeft}>
                    <div className={style.panelHead}>
                        <div className={style.pageTitle}>STUDENTS DASHBOARD</div>
                    </div>
                    <div className={style.panelBody}>
                        <div className={style.pageDescription}>
                            <span className={style.descriptionText}>
                                Select students from the table to start creating and administering Treatment Plans
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style.panelRight} />
            </div>
        </div>
    );
    return (
        <StudentTreatmentDashboard
            collectionBuckets={['Student-led', 'Provider-led']}
            contactTypes={['Mental Health Provider']}
            header={header}
            logo={ShareLogo}
            progressQuery={progressQuery}
            addToCollectionButtonLabel="Add to Plan"
            assessmentLibraryHandle="TreatmentLibrary"
        />
    );
};

export default DeploymentCenterPage;
