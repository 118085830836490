import React from 'react';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'cccisd-react-router';
import { AdminRoutes, Manage as AppdefManage } from 'cccisd-laravel-appdefs';
import { ResourceCenterWithCollection } from 'cccisd-laravel-resources/dist/Collection';
import { ResourceCenter, ViewShare } from 'cccisd-laravel-resources';
import StyledResourceCenter from './components/StyledResourceCenter';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { Quest } from 'cccisd-laravel-assignment';
import { Flags, Results } from 'cccisd-laravel-assessment';

// Layouts
import MainLayout from './layouts/MainLayout';
import QuestLayout from './layouts/QuestLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome';
import DeploymentCenter from './pages/DeploymentCenter';
import AssignmentPlanPlayer from './pages/AssignmentPlan';
import ComingSoon from './pages/ComingSoon';
import Administer from './pages/Administer';
import MockupPage from './pages/MockupPage';
import RolePicker from './pages/RolePicker';
import SessionPlayer from './pages/SessionPlayer';
import StudentTreatmentDashboard from './components/StudentTreatmentDashboard';
import TreatmentAdminister from './pages/TreatmentAdminister';
import TreatmentStrategies from './pages/TreatmentStrategies';
import StudentTreatmentStrategies from './pages/StudentTreatmentStrategies';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: MainLayout })(Route);

const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const componentMatch = {
    AppdefManage,
    Welcome,
    ResourceCenter,
    StyledResourceCenter,
    ResourceCenterWithCollection,
    DeploymentCenter,
    AssignmentPlanPlayer,
    Administer,
    ComingSoon,
    Flags,
    Results,
    StudentTreatmentDashboard,
    TreatmentAdminister,
    MockupPage,
    TreatmentStrategies,
    StudentTreatmentStrategies,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = componentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={
                        'layout' in route && layoutMatch[route.layout] ? layoutMatch[route.layout] : MainLayoutFluid
                    }
                />
            );
        })}
        <AppRoute path="/" component={Welcome} exact />
        <AppRoute path="/welcome" component={Welcome} />
        <AppRoute path="/my-account" component={RolePicker} />
        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={QuestLayout} />
        <AppRoute
            path="/sessionPlayer/:deploymentId/:assignmentPlanId/:sessionId/:reporter/:pawnId/:pawnHash"
            component={SessionPlayer}
            componentProps={{
                errorLayout: MainLayoutFluid,
            }}
            layout={MainLayoutFluid}
        />
        <AppRoute
            path="/results/:assignmentPlanId/:pawnId"
            component={Results}
            componentProps={{
                errorLayout: MainLayoutFluid,
            }}
            layout={MainLayoutFluid}
        />
        {/* Laravel packages routes */}
        {AdminRoutes(MainLayoutFluid)}
        <AppRoute key="ViewShare" path="/resource/viewshare/:id/:hash" component={ViewShare} exact />;
        <AppRoute
            path="/account"
            layout={null}
            component={Nexus}
            componentProps={{ defaultLayout: MainLayout, registrationSurveyLayout: MainLayout }}
        />
        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />
        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
