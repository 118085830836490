import React from 'react';
import style from './style.css';

export const highlight = string => {
    return <span style={{ backgroundColor: '#2c817c', color: '#fff', fontSize: '0.9em' }}>{string}</span>;
};

const HelpModal = props => {
    return (
        <div className={style.wrapper}>
            <div className={style.content}>
                <div className={style.panel}>
                    <div className={style.panelHead}>STEP 1</div>
                    {/* eslint-disable react/jsx-curly-brace-presence */}
                    <div className={style.panelBody}>
                        Add chosen assessments to the Assessment Plan by clicking {highlight('Add to Collection')} on
                        the assessment tile.
                    </div>
                </div>
                <div className={style.panel}>
                    <div className={style.panelHead}>STEP 2</div>
                    <div className={style.panelBody}>
                        Click {highlight('Review')} in the upper-right corner to review your current Assessment Plan.
                    </div>
                </div>
                <div className={style.panel}>
                    <div className={style.panelHead}>STEP 3</div>
                    <div className={style.panelBody}>Click each reporter type to review their assessments.</div>
                </div>
                <div className={style.panel}>
                    <div className={style.panelHead}>STEP 4</div>
                    <div className={style.panelBody}>
                        Click {highlight('Edit')} in the upper-right corner of each Assessment Plan Timepoint to remove
                        unwanted assessments, reorder assessments, and edit the Timepoint Name if desired.
                    </div>
                </div>
                <div className={style.panel}>
                    <div className={style.panelHead}>STEP 5</div>
                    <div className={style.panelBody}>
                        Click {highlight('Add Timepoint')} to collect the same assessment data at a later date (e.g.,
                        for a 1-month follow-up).
                    </div>
                </div>

                <div className={style.panel}>
                    <div className={style.panelHead}>STEP 6</div>
                    <div className={style.panelBody}>
                        Name your Assessment Plan and click {highlight('Submit')} to save.
                    </div>
                </div>
                {/* <div
                    className={style.panel}
                    style={{ border: '1px solid #154988', borderRadius: '4px', padding: '1em', fontSize: '0.9em' }}
                >
                    <div className={style.panelHead}>NOTE</div>
                    <div
                        className={style.panelBody}
                    >{`Select all Assessments that you want to administer to your students, their caregivers, educators, and mental health providers as part of this Assessment Plan`}</div>
                </div> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button type="button" className="btn btn-secondary" onClick={() => props.closeModal()}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default HelpModal;
