import React, { useEffect, useRef } from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';
import { Link } from 'cccisd-react-router';
import Modal from 'cccisd-modal';
import { highlight } from '../HelpModal';
import style from './style.css';

function renderResourceTiles(data, renderDefault) {
    return <div className={style.resourceTiles}>{renderDefault(data)}</div>;
}

const Fortress = window.cccisd.fortress;

const StyledResourceCenter = props => {
    let locationHash = window.location.hash;
    const modalRef = useRef(null);

    useEffect(() => {
        if (locationHash === '#help') {
            modalRef.current.open();
        }
    }, [locationHash]);

    const getContents = role => {
        if (role === 'uberadmin') {
            return (
                <>
                    <div style={{ fontSize: '1.5em', fontWeight: 'bold', color: '#154988', marginBottom: '1em' }}>
                        You must be logged in as a Provider to create Assessment Plans
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link to="/manage#instructor" className="btn btn-primary" style={{ marginRight: '1em' }}>
                            <>Go to Manage Page</>
                        </Link>
                        <button type="button" className="btn btn-secondary" onClick={() => modalRef.current.close()}>
                            Close
                        </button>
                    </div>
                </>
            );
        }

        if (role === 'instructor') {
            return (
                <>
                    <div style={{ fontSize: '1.5em', fontWeight: 'bold', color: '#154988', marginBottom: '1em' }}>
                        To create an Assessment Plan, you must first return to the Admin Center, select the appropriate
                        student(s), and click {highlight('Create Assessment')}. This will take you to the Assessment
                        Library. Then, click {highlight('How to Create an Assessment Plan')} for detailed steps on
                        creating an Assessment Plan.
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link to="/administration" className="btn btn-primary" style={{ marginRight: '1em' }}>
                            <>Go to Admin Center</>
                        </Link>
                        <button type="button" className="btn btn-secondary" onClick={() => modalRef.current.close()}>
                            Close
                        </button>
                    </div>
                </>
            );
        }
    };

    return (
        <>
            <Modal
                trigger={null}
                size="large"
                ref={modalRef}
                beforeClose={() => {
                    window.location.hash = '';
                    return null;
                }}
                title="How to create an Assessment Plan"
            >
                <div style={{ padding: '1em' }}>{getContents(Fortress.user.acting.data_type)}</div>
            </Modal>
            <ResourceCenter handle={props.handle || 'default'} renderResourceTiles={renderResourceTiles} />
        </>
    );
};

export default StyledResourceCenter;
