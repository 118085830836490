import React from 'react';
import Header from 'cccisd-header';
import style from './style.css';

const Fortress = window.cccisd.fortress;

export default props => {
    const { hideSecondaryNav } = props;
    const wpUrl = window.cccisd.env.wpUrl || 'https://wp.share.3cimpact.com';
    const primaryNavLinks = [{ url: wpUrl + '/contact', label: 'Contact' }];

    if (Fortress?.user?.pawns?.length > 1) {
        primaryNavLinks.unshift({ url: '/my-account', label: 'My Account' });
    }

    return (
        <div>
            <Header
                containerClassName="container-fluid"
                wrapperClassName={style.wrapper}
                logoClassName={style.logo}
                // primaryNavLinks={primaryNavLinks}
                secondaryNavLinks={hideSecondaryNav ? '' : 'header'}
                showHomeAction={false}
                showAdminAction={Fortress.hasRole('uberadmin')}
                showAlertAction={!Fortress.hasRole('learner')}
                // showUserNav={!Fortress.hasRole('learner')}
            />
        </div>
    );
};
